<template>
  <div>
    <div>
      <h5 class="is-size-5 has-text-weight-bold has-text-primary">
        Bulk Payment
      </h5>
      <ZuDivider />
      <div
        v-for="(item) in list"
        :key="item.order.orderReference"
        class="list is-gapless is-narrow is-topless is-align-flex-start is-bottomless">
        <div class="list-content has-line-height-1-25">
          {{ item.order.orderReference }}
        </div>
        <div class="list-action">
          <div class="has-text-weight-bold has-text-right">
            {{ item.order.agentActualChargeAmount | currencyObj }}
          </div>
          <div
            v-if="paymentMethod === 'creditcard'"
            class="has-text-grey-light is-size-7">
            {{ $t('views.book.Included transaction fee', { fee: `${finalAdjustments.length && finalAdjustments[0].value * 100}%` }) }} {{ {value: item.order.finalAdjustmentAmount, currency: item.order.finalAdjustmentAmountCcy}|currencyObj }}
          </div>
        </div>

        <!-- <div v-if="item.order.finalAdjustmentAmount > 0" class="list-content">
          <span class="has-text-weight-bold">{{ {value: item.order.finalAdjustmentAmount, currency: item.order.finalAdjustmentAmountCcy}|currencyObj }}</span>
        </div> -->
      </div>
      <!-- <div
        v-if="paymentMethod === 'creditcard' && finalAdjustments && finalAdjustments.length"
        class="list is-gapless is-narrow">
        <div
          class="list-content"
          style="align-self: flex-start;">
          <span>{{ $t('views.book.Transaction fee') }} ({{ `${finalAdjustments[0].value * 100}%` }})</span>
        </div>
        <div class="list-action has-text-right">
          <span class="has-text-weight-bold">{{ finalAdjustmentAmount | currencyObj }}</span>
        </div>
      </div> -->
      <ZuDivider />
      <div v-if="additionalFee && additionalFee.value">
        <div class="list is-gapless is-narrow">
          <div class="list-content">
            Additional Fee
          </div>
          <div class="list-action">
            <div class="has-text-weight-bold has-text-right">
              {{ additionalFee | currencyObj }}
            </div>
          </div>
        </div>
        <ZuDivider />
      </div>
      <div class="list is-gapless is-narrow">
        <div
          class="list-content"
          style="align-self: flex-start;">
          <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('views.CheckoutFormSummary.total-amount') }}</span>
          <div
            v-if="paymentMethod!=='creditcard'"
            class="has-text-grey-light is-size-7">
            {{ $t('views.CheckoutFormSummary.include') }}
          </div>
        </div>
        <div class="list-action has-text-right">
          <span class="has-text-weight-bold has-text-primary is-size-4">{{ totalAmount|currencyObj }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'BulkPaymentSummary',
  computed: {
    ...mapState('bulkPayment', ['paymentMethod', 'list', 'convertedTotalAmount', 'finalAdjustments', 'finalAdjustmentAmount', 'additionalFee']),
    ...mapGetters('bulkPayment', ['totalAmount'])
  },
  methods: {
    orderAmount (item) {
      if (this.paymentMethod === 'walletUSD') {
        return item.order.actualChargeAmount
      }
      return item.order.agentActualChargeAmount
    }
  }
}
</script>

<style lang="scss">
  .refundable {
    padding-left: 20px;
    position: relative;

    .ap-icon-check {
      position: absolute;
      top: 2px;
      left: 0;
    }
  }

</style>
