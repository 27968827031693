<template>
  <div class="book-page">
    <!-- Loading Bar start -->
    <progress-indicator
      v-if="isContinuePulling" />
    <!-- Loading Bar end -->
    <!-- title start -->
    <div class="section has-background-primary has-text-white">
      <div class="container is-tablet p-t-10x has-text-centered">
        <h1 class="title is-size-3 m-b has-text-white">
          <i
            v-if="isContinuePulling === false && payment.status === 'succeeded'"
            class="ap-icon-check has-text-white" />
          <i
            v-if="isContinuePulling === false && payment.status === 'failed'"
            class="ap-icon-close has-text-danger" />
          {{ isContinuePulling === false ? payment.status === 'succeeded' ? $t('views.book.PaymentConfirm.title') : payment.failureMessage : $t('views.book.CaptureInProgress.title') }}
        </h1>
        <span v-if="isContinuePulling === false">
          {{ payment.status === 'succeeded' ? $t('go-bookings-page') : '' }}
        </span>
      </div>
    </div>
    <!-- title end -->
    <div class="section">
      <div class="container is-tablet half-list-wrapper">
        <div
          v-for="item in orders"
          :key="item.order.orderReference">
          <div class="list is-gapless is-narrow is-topless is-bottomless">
            <div class="list-content">
              {{ item.order.orderReference }}
            </div>
            <div class="list-action">
              {{ item.order.agentActualChargeAmount | currencyObj }}
            </div>
          </div>
          <div
            v-if="item.order.finalAdjustmentMeta.length"
            class="list is-gapless is-narrow is-topless  is-bottomless m-b">
            <div
              class="list-content"
              style="align-self: flex-start;">
              <span class="has-text-grey-light is-size-7">{{ $t('views.book.Transaction fee') }}</span>
            </div>
            <div class="list-action has-text-right">
              <span class="has-text-grey-light is-size-7">{{ {value: item.order.finalAdjustmentAmount, currency: item.order.finalAdjustmentAmountCcy } | currencyObj }}</span>
            </div>
          </div>
          <ZuDivider class="is-border-light" />
        </div>
        <div v-if="additionalFee && additionalFee.value > 0">
          <div class="list is-gapless is-narrow">
            <div class="list-content">
              Additional Fee
            </div>
            <div class="list-action">
              <div class="has-text-right">
                {{ additionalFee | currencyObj }}
              </div>
            </div>
          </div>
          <ZuDivider />
        </div>
        <div class="list is-gapless is-narrow">
          <div class="list-content">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('views.CheckoutFormSummary.total-amount') }}</span>
          </div>
          <div class="list-action">
            <span class="has-text-weight-bold">{{ totalAmount | currencyObj }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import ProgressIndicator from '@/components/ProgressIndicator'

const asyncData = async ({ params }) => {
  try {
    await store.dispatch('bulkPayment/retrievePaymentStatus', params.paymentToken)
  } catch (err) {
    store.commit('SET_GLOBAL_ERROR_MODAL', {
      show: true,
      okBtn: true,
      content: err.message
    })
  }
}
export default {
  name: 'BulkPaymentComplete',
  components: { ProgressIndicator },
  i18n: {
    messages: {
      'en-US': {
        'go-bookings-page': 'Go to manage bookings to check the latest booking status'
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      await asyncData({ params: to.params })
      next()
    } catch (err) {
      next()
    }
  },
  beforeRouteLeave (to, from, next) {
    clearTimeout(this.pullingTimerHandle)
    next()
  },
  computed: {
    ...mapState('bulkPayment', {
      orders: state => state.orders,
      payment: state => state.payment,
      isContinuePulling: state => state.continuePulling,
      totalAmount: state => state.totalAmount,
      additionalFee: state => state.additionalFee
    })
  },
  watch: {
    'isContinuePulling': {
      immediate: true,
      handler (nV, oV) {
        if (nV) {
          clearTimeout(this.pullingTimerHandle)
          this.pullingTimerHandle = setTimeout(() => this.polling(), 5e3)
        }
      }
    }
  },
  async created () {
    await this.polling()
  },
  mounted () {
    this.$store.commit('bulkPayment/SET_DATA', { continuePulling: true })
  },
  methods: {
    async polling () {
      this.$store.commit('bulkPayment/SET_DATA', { continuePulling: null })
      await asyncData({ params: this.$route.params })
    }
  }
}
</script>

<style scoped>

</style>
