<template>
  <div class="book-page">
    <div class="section">
      <div class="container">
        <!--breadcrumb start -->
        <div class="columns m-b-2x">
          <div class="column is-12 is-mobile">
            <PpBreadcrumb
              :routes="breadCrumb"
              class="has-text-weight-bold search-one--breadcrumb is-inline" />
            <span class="has-text-secondary has-text-weight-bold">{{ $t('views.book.Payment') }}</span>
          </div>
        </div>
        <!--breadcrumb end -->
        <h2 class="title is-size-3 has-text-primary m-b-5x">
          {{ $t('views.book.Payment') }}
        </h2>
        <!-- <el-alert
          v-if="error"
          :title="error.message"
          :closable="false"
          type="error" /> -->
        <div
          v-if="!error"
          v-loading="isFetching"
          class="columns">
          <div class="column is-8">
            <div class="tabs is-boxed">
              <ul>
                <li
                  v-if="!$hasRole('CLIENT_AGENT_SB') && wallet.ZAR.isActive"
                  :class="{'is-active':paymentMethod==='walletZAR'}"
                  @click="paymentMethodChange('walletZAR')">
                  <a>
                    <span>{{ $t('views.book.Wallet') }} (ZAR)</span>
                  </a>
                </li>
                <li
                  v-if="!$hasRole('CLIENT_AGENT_SB') && wallet.USD.isActive"
                  :class="{'is-active':paymentMethod==='walletUSD'}"
                  @click="paymentMethodChange('walletUSD')">
                  <a>
                    <span>{{ $t('views.book.Wallet') }} (USD)</span>
                  </a>
                </li>
                <li
                  :class="{'is-active':paymentMethod==='creditcard'}"
                  @click="paymentMethodChange('creditcard')">
                  <a>
                    <span>{{ $t('views.book.Credit card') }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="payment-methods">
              <Wallet
                v-if="paymentMethod==='walletZAR' && !$hasRole('CLIENT_AGENT_SB')"
                :amount="totalAmount"
                :enable="$enableWallet()"
                currency="ZAR"
                is-bulk-payment
                pay-type="payLater" />
              <Wallet
                v-if="paymentMethod==='walletUSD' && !$hasRole('CLIENT_AGENT_SB')"
                :amount="totalAmount"
                :enable="$enableWallet()"
                is-bulk-payment
                currency="USD"
                pay-type="payLater" />
              <div
                v-if="paymentMethod==='creditcard'"
                class="payment-balance">
                <div class="payment-balance-detail">
                  <PpSpiner v-if="dropinLoading" />
                  <AdyenPayment
                    :amount="totalAmount"
                    is-bulk-payment
                    pay-type="payLater"
                    @ready="dropinLoading = false" />
                  <!-- <a
                    v-if="paymentUrl"
                    :href="paymentUrl"
                    class="el-button button-secondary"
                    style="min-width: 155px"><i class="el-icon-bank-card m-r"/> Pay Now</a> -->
                </div>
              </div>
            </div>
          </div>

          <div class="column is-4">
            <BulkPaymentSummary />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import store from '@/store'
import Wallet from '@/views/payment/Wallet'
import AdyenPayment from '@/components/AdyenPayment'
import { retrieveAgent } from '@/service/auth'
import BulkPaymentSummary from '../../components/BulkPaymentSummary'

export default {
  name: 'BulkPayment',
  components: {
    AdyenPayment,
    BulkPaymentSummary,
    Wallet
  },
  beforeRouteEnter (to, from, next) {
    if (!to.params.orderReference) {
      store.commit('SET_GLOBAL_MODAL', {
        show: true,
        content: 'Missing orderReference'
      })
    }
    store.dispatch('bulkPayment/enquiry', { orderReference: to.params.orderReference.split(','), paymentType: 'creditcard' }).then(() => next()).catch(() => next())
  },
  data () {
    return {
      // showPaymentIframe: false,
      dropinLoading: true,
      wallet: retrieveAgent('wallet')
    }
  },
  computed: {
    ...mapState('bulkPayment', ['paymentMethod', 'paymentUrl', 'isFetching', 'error']),
    ...mapState('bulkPayment', { list: state => state.list }),
    ...mapGetters('bulkPayment', ['totalAmount']),
    breadCrumb () {
      return [
        {
          label: this.$t('views.TheHeader.settle-payment'),
          replace: true,
          route: { name: 'settle-payment' }
        }
      ]
    },
    booking () {
      return (this.list[0] && this.list[0].booking) || {}
    }
  },
  created () {
    this.$nextTick(() => {
      if (this.error !== undefined) {
        this.$store.commit(
          'SET_GLOBAL_MODAL',
          { show: true, content: this.error.message },
          { root: true }
        )
      }
    })
  },
  methods: {
    paymentMethodChange (method) {
      store.commit('bulkPayment/SET_DATA', { paymentMethod: method })
      store.dispatch('bulkPayment/enquiry', { orderReference: this.$route.params.orderReference.split(','), paymentType: method })
    }
  },
  metaInfo () {
    return {
      title: 'Bulk Payment'
    }
  }
}
</script>

<style scoped>

</style>
