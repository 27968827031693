import { render, staticRenderFns } from "./BulkPaymentComplete.vue?vue&type=template&id=a5ddbd1c&scoped=true&"
import script from "./BulkPaymentComplete.vue?vue&type=script&lang=js&"
export * from "./BulkPaymentComplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5ddbd1c",
  null
  
)

export default component.exports